import { useTranslation } from 'react-i18next'
import {
  ReservationShowEntity,
  ResourceEntity,
  SimpleResourceWithPolicyEntity,
  useResourceReservationAdminGetExtendTimeOptions,
  useResourceReservationAdminUpdate,
  useResourceReservationGetExtendTimeOptions,
  useResourceReservationUpdate,
} from '@/api'
import { KBFlex, KBModal, KBText } from '@/components/atoms'
import { kbMessage } from '@/components/atoms/KBMessageGlobal'
import { isUserClient } from '@/utils/userEnvironment'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { formatDate, formatTime } from '@wox/dayjs'

interface ExtendMeetingModalProps {
  reservation?: ReservationShowEntity
  resource?: SimpleResourceWithPolicyEntity | Partial<ResourceEntity>
}

function ExtendMeetingModal(props: ExtendMeetingModalProps) {
  const { t } = useTranslation()
  const { reservation, resource } = props
  const { start_at, id } = reservation!

  const modal = useModal()

  const [selectTime, setSelectTime] = useState<number | undefined>()

  const timeData = isUserClient()
    ? useResourceReservationGetExtendTimeOptions(id!)
    : useResourceReservationAdminGetExtendTimeOptions(id!)

  const timeOptions = timeData?.data || []

  const editApi = isUserClient()
    ? useResourceReservationUpdate
    : useResourceReservationAdminUpdate
  const { mutate: updateResourceReservation, isPending: updating } = editApi({})

  const hoverClass = ` hover:tw-bg-[var(--wox-color-primary)] hover:tw-text-white `
  const selectClass = `tw-bg-[var(--wox-color-primary)] tw-text-white`

  const delayTime = () => {
    if (selectTime) {
      updateResourceReservation(
        {
          id,
          data: {
            start_at,
            end_at: timeOptions[selectTime - 1]?.end_at!,
            resource_id: resource?.id!,
            update_mode: 'extend',
          },
        },
        {
          onSuccess: () => {
            modal.hide()
            kbMessage.success(t('common.delaySuccess'))
          },
        }
      )
    }
  }

  return (
    <KBModal
      modal={modal}
      title={t('common.extendReservation')}
      width={384}
      onOk={delayTime}
    >
      <KBFlex vertical gap={8} className="tw-w-full">
        {timeOptions?.map((time, index) => {
          return (
            <KBText
              className={`tw-block  tw-rounded-md tw-bg-[var(--wox-color-fill-quaternary)] tw-px-4 tw-py-2.5 
                ${time?.is_enable ? (selectTime === index + 1 ? selectClass : hoverClass) + ' tw-cursor-pointer' : 'tw-cursor-not-allowed tw-text-[var(--wox-color-text-quaternary)]'}
               `}
              key={index}
              onClick={() => {
                time?.is_enable && setSelectTime(index + 1)
              }}
            >
              {t('common.delayTo', {
                time: time.is_full_day
                  ? formatDate(time?.end_at!)
                  : formatTime(time?.end_at!),
                duration: time.is_full_day
                  ? t('common.numberDay', { day: time?.time })
                  : t('common.minutes', { minutes: time?.time }),
              })}
            </KBText>
          )
        })}
      </KBFlex>
    </KBModal>
  )
}

export default NiceModal.create(ExtendMeetingModal)
