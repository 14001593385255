// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ResourceReservationAdminUpdateMutationRequest, ResourceReservationAdminUpdateMutationResponse, ResourceReservationAdminUpdatePathParams, ResourceReservationAdminUpdate401, ResourceReservationAdminUpdate406, ResourceReservationAdminUpdate409 } from "../models/ResourceReservationAdminUpdate";
import type { UseMutationOptions } from "@tanstack/react-query";

 type ResourceReservationAdminUpdateClient = typeof client<ResourceReservationAdminUpdateMutationResponse, ResourceReservationAdminUpdate401 | ResourceReservationAdminUpdate406 | ResourceReservationAdminUpdate409, ResourceReservationAdminUpdateMutationRequest>;
type ResourceReservationAdminUpdate = {
    data: ResourceReservationAdminUpdateMutationResponse;
    error: ResourceReservationAdminUpdate401 | ResourceReservationAdminUpdate406 | ResourceReservationAdminUpdate409;
    request: ResourceReservationAdminUpdateMutationRequest;
    pathParams: ResourceReservationAdminUpdatePathParams;
    queryParams: never;
    headerParams: never;
    response: ResourceReservationAdminUpdateMutationResponse;
    client: {
        parameters: Partial<Parameters<ResourceReservationAdminUpdateClient>[0]>;
        return: Awaited<ReturnType<ResourceReservationAdminUpdateClient>>;
    };
};
/**
 * @summary 更新当前预定，如果是周期性预定更新后周期性为exception，如果是集合子预定更新只支持更新主持人，如果是周期性预定不能更新为非周期性预定
 * @link /api/v5/admin/resource-reservations/:id
 */
export function useResourceReservationAdminUpdate(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<ResourceReservationAdminUpdate["response"], ResourceReservationAdminUpdate["error"], {
        id: ResourceReservationAdminUpdatePathParams["id"];
        data?: ResourceReservationAdminUpdate["request"];
    }>;
    client?: ResourceReservationAdminUpdate["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ id, data }) => {
            const res = await client<ResourceReservationAdminUpdate["data"], ResourceReservationAdminUpdate["error"], ResourceReservationAdminUpdate["request"]>({
                method: "patch",
                url: `/api/v5/admin/resource-reservations/${id}`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "admin"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}