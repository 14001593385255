import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  CancelVisitorDtoAction,
  useVisitorAdminApproveVisitor,
  useVisitorAdminCancel,
  useVisitorAdminVisitorSignIn,
  useVisitorAdminVisitorSignOut,
  useVisitorCancel,
  useVisitorSignIn,
  useVisitorSignOut,
  useVisitorUpdateOne,
  useVisitorVisitorApprovalPassed,
  VisitorEntity,
} from '@/api'
import {
  KBButton,
  KBDropdown,
  KBFlex,
  KBIcon,
  KBTag,
  KBText,
  KBTooltip,
} from '@/components/atoms'
import { kbMessage, kbModal } from '@/components/atoms/KBMessageGlobal'
import { KBUser } from '@/components/organisms'
import VisitorStateIcon from '@/components/organisms/KBVisitorListItem/VisitorStateIcon'
import { VisitorUnifiedStatusEnum } from '@/enums/modelEnums'
import useCurrentLocationId from '@/hooks/useCurrentLocationId'
import { isUserClient } from '@/utils/userEnvironment'
import { formatDateMonthDay, formatTime } from '@wox/dayjs'
import { MenuProps } from 'antd'

interface KBVisitorListItemProps {
  visitor: VisitorEntity
  refetch?: () => void
  onEditVisitor?: (visitor: VisitorEntity, isInviteAgain?: boolean) => void
  onRejectVisitor?: (visitor: VisitorEntity) => void
  /**
   * @description 是否只读
   */
  readonly?: boolean
}

const KBVisitorListItem: React.FC<KBVisitorListItemProps> = ({
  visitor,
  refetch,
  onEditVisitor,
  onRejectVisitor,
  readonly = false,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currentLocationId = useCurrentLocationId()
  const [isAnimating, setIsAnimating] = useState(false)
  const { mutate: updateVisitor } = useVisitorUpdateOne({
    invalidationOnSuccess: false,
  })

  const signInApi = isUserClient()
    ? useVisitorSignIn
    : useVisitorAdminVisitorSignIn

  const { mutate: signInVisitor } = signInApi()

  const { mutate: signOutVisitor } = isUserClient()
    ? useVisitorSignOut()
    : useVisitorAdminVisitorSignOut()

  const { mutate: approveVisitor } = isUserClient()
    ? useVisitorVisitorApprovalPassed()
    : useVisitorAdminApproveVisitor()

  const cancelApi = isUserClient() ? useVisitorCancel : useVisitorAdminCancel

  const { mutate: cancelVisitor } = cancelApi({
    invalidationOnSuccess: false,
  })

  // const visitorConfigApi = isUserClient()
  //   ? useVisitorConfigFindByLocationId
  //   : useVisitorConfigAdminFindByLocationId
  // const { data: visitorConfig } = visitorConfigApi(
  //   {
  //     location_id: currentLocationId!,
  //   },
  //   {
  //     query: {
  //       enabled: !readonly,
  //     },
  //   }
  // )

  const handleCancel = (action?: CancelVisitorDtoAction) => {
    cancelVisitor(
      {
        id: visitor?.id,
        data: {
          action: action || 'current',
        },
      },
      {
        onSuccess: () => {
          kbMessage.success(t('common.cancelSuccess'))
          refetch?.()
        },
      }
    )
  }

  const handleApprove = () => {
    approveVisitor(
      {
        id: visitor?.id,
      },
      {
        onSuccess: () => {
          kbMessage.success(t('common.approveSuccess'))
          refetch?.()
        },
      }
    )
  }

  const recurrentTypes = [
    {
      key: 'current',
      label: t('reservation.currentInvitation'),
    },
    {
      key: 'current_to_end',
      label: t('reservation.currentAndSubsequentInvitations'),
    },
    {
      key: 'serial',
      label: t('reservation.allInvitations'),
    },
  ]

  const actionItems: MenuProps['items'] = []

  if (visitor.unified_status !== VisitorUnifiedStatusEnum.cancelled) {
    actionItems.push({
      key: 'edit',
      label: (
        <KBFlex
          gap={8}
          align="center"
          onClick={() => onEditVisitor && onEditVisitor(visitor)}
        >
          <KBIcon name="square-pen" />
          <KBButton className="tw-h-fit tw-p-0" type="text">
            {t('common.edit')}
          </KBButton>
        </KBFlex>
      ),
    })
  }

  // 目前列表页面只能管理员审批和拒绝，因为每个访客的location_id不一定一样
  // 如果要判断用户是否可以审批，需要获取每个location的visitConfig设置，处理比较复杂
  // 所以目前用户只能到详情页面审批
  const canUserApprove = false
  // ((visitorConfig?.approval?.approval_by_host_user &&
  //   visitor?.request_type === VisitorRequestTypeEnum.invite) ||
  //   (visitorConfig?.approval?.apply_approval_by_host_user &&
  //     visitor?.request_type === VisitorRequestTypeEnum.request))

  const canInviteAgain =
    visitor?.unified_status === VisitorUnifiedStatusEnum.waiting_for_sign ||
    visitor?.unified_status === VisitorUnifiedStatusEnum.signed_in ||
    visitor?.unified_status === VisitorUnifiedStatusEnum.signed_out ||
    visitor?.unified_status === VisitorUnifiedStatusEnum.cancelled

  if (canInviteAgain) {
    actionItems.push({
      key: 'inviteAgain',
      label: (
        <KBFlex
          gap={8}
          align="center"
          onClick={() => onEditVisitor && onEditVisitor(visitor, true)}
        >
          <KBIcon name="file-pen"></KBIcon>
          <KBButton className="tw-h-fit tw-p-0" type="text">
            {t('common.inviteAgain')}
          </KBButton>
        </KBFlex>
      ),
    })
  }

  if (
    visitor.unified_status !== VisitorUnifiedStatusEnum.signed_out &&
    visitor.unified_status !== VisitorUnifiedStatusEnum.cancelled
  ) {
    actionItems.push({
      key: 'Cancel',
      label: (
        <KBFlex gap={8} align="center">
          <KBIcon name="trash-2" className="tw-text-red-500" />
          {visitor?.is_recurrent ? (
            <KBDropdown
              menu={{
                items: recurrentTypes,
                onClick: (e) => {
                  handleCancel(e.key as CancelVisitorDtoAction)
                  console.log(e.key)
                },
              }}
            >
              <KBButton className="tw-h-fit tw-p-0" type="text">
                <KBText className="tw-text-red-500">
                  {t('common.cancelInvite')}
                </KBText>
              </KBButton>
            </KBDropdown>
          ) : (
            <KBButton
              className="tw-h-fit tw-p-0"
              type="text"
              onClick={() => {
                kbModal.confirm({
                  title: t('common.cancelInvite'),
                  content: t('common.cancelInviteConfirm'),
                  onOk: () => {
                    handleCancel()
                    console.log('cancelVisitor')
                  },
                })
              }}
            >
              <KBText className="tw-text-red-500">
                {t('common.cancelInvite')}
              </KBText>
            </KBButton>
          )}
        </KBFlex>
      ),
    })
  }

  if (visitor.unified_status === VisitorUnifiedStatusEnum.signed_in) {
    actionItems.push({
      key: 'signOut',
      label: (
        <KBFlex
          gap={8}
          align="center"
          onClick={() => {
            signOutVisitor(
              {
                id: visitor?.id,
              },
              {
                onSuccess: () => {
                  kbMessage.success(t('common.signOutSuccess'))
                  refetch?.()
                },
              }
            )
          }}
        >
          <KBIcon name="circle-x"></KBIcon>
          <KBButton className="tw-h-fit tw-p-0" type="text">
            {t('common.signOff')}
          </KBButton>
        </KBFlex>
      ),
    })
  } else if (visitor.unified_status === VisitorUnifiedStatusEnum.approving) {
    if (!isUserClient() || canUserApprove) {
      actionItems.push({
        key: 'Reject',
        label: (
          <KBFlex
            gap={8}
            align="center"
            onClick={() => onRejectVisitor && onRejectVisitor(visitor)}
          >
            <KBIcon name="circle-x" />
            <KBButton className="tw-h-fit tw-p-0" type="text">
              {t('common.reject')}
            </KBButton>
          </KBFlex>
        ),
      })
      actionItems.push({
        key: 'Approve',
        label: (
          <KBFlex gap={8} align="center" onClick={() => handleApprove()}>
            <KBIcon name="circle-check" />
            <KBButton className="tw-h-fit tw-p-0" type="text">
              {t('common.approve')}
            </KBButton>
          </KBFlex>
        ),
      })
    }
  } else if (
    visitor.unified_status === VisitorUnifiedStatusEnum.waiting_for_sign
  ) {
    actionItems.push({
      key: 'signIn',
      label: (
        <KBFlex
          gap={8}
          align="center"
          onClick={() => {
            signInVisitor(
              {
                id: visitor?.id,
                // data: {
                //   unified_status: VisitorUnifiedStatusEnum.signed_in,
                // },
              },
              {
                onSuccess: () => {
                  kbMessage.success(t('common.signInSuccess'))
                  refetch?.()
                },
              }
            )
          }}
        >
          <KBIcon name="circle-check"></KBIcon>
          <KBButton className="tw-h-fit tw-p-0" type="text">
            {t('common.signIn')}
          </KBButton>
        </KBFlex>
      ),
    })
  }

  const gotoVisitorInfo = () => {
    if (readonly) return
    if (isUserClient()) {
      navigate(`/user/visitors/${visitor?.id}`)
    } else {
      navigate(`/admin/locations/${currentLocationId!}/visitors/${visitor?.id}`)
    }
  }

  return (
    <KBFlex
      justify="space-between"
      gap={16}
      className="tw-box-border tw-cursor-default tw-border tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-[var(--wox-color-border-secondary)] tw-px-2 tw-py-3 hover:tw-cursor-pointer hover:tw-bg-[var(--wox-color-fill-quinary)]"
      onClick={gotoVisitorInfo}
    >
      <KBFlex
        gap={8}
        align="center"
        className="tw-w-3/4 tw-shrink tw-overflow-hidden"
      >
        <KBText className="tw-flex-shrink-0">
          {visitor?.expect_arrival_date
            ? formatDateMonthDay(visitor?.expect_arrival_date)
            : ''}
        </KBText>

        {/* 外边包一层div 阻止click冒泡 */}
        <div
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <KBDropdown
            trigger={['click']}
            placement="bottom"
            menu={{
              items: actionItems,
            }}
          >
            <a href="#">
              <VisitorStateIcon
                visitorState={visitor.unified_status}
                showTooltip
              />
            </a>
          </KBDropdown>
        </div>

        {/* <KBEnumTag
            enums={VisitorUnifiedStatusEnumObjects}
            value={visitor?.unified_status!}
            modelName="VisitorUnifiedStatusEnum"
           /> */}
        <KBTag className="tw-mr-0 tw-w-fit tw-flex-shrink-0 tw-border-[var(--wox-color-border)]">
          <KBText
            level={3}
            className="tw-text-[var(--wox-color-text-secondary)]"
          >
            {visitor?.visitor_type?.name}
          </KBText>
        </KBTag>
        <KBUser
          className="tw-flex-shrink-0"
          user={{
            avatar: visitor?.visitor_user?.avatar,
            name: visitor?.name,
            email: visitor?.email,
            company: visitor?.company,
          }}
          showName={true}
          showTooltip
        />

        <div className="tw-truncate">
          <KBText>{visitor?.note}</KBText>
        </div>
      </KBFlex>
      <KBFlex gap={8} align="center">
        {isUserClient() && (
          <KBFlex
            align="center"
            gap={4}
            className="tw-rounded-full tw-border tw-border-solid tw-border-[var(--wox-color-border-secondary)]  tw-px-2 tw-py-1"
          >
            <KBIcon
              name="building"
              className="tw-text-[var(--wox-color-primary)]"
            />

            <KBText level={3}>{visitor?.location?.name}</KBText>
          </KBFlex>
        )}
        <KBTooltip title={t('common.expectedVisitTime')}>
          <KBText level={3} className="tw-text-gray-600">
            {formatTime(visitor?.expect_visit_at!)}
          </KBText>
        </KBTooltip>
        {visitor?.unified_status === VisitorUnifiedStatusEnum.signed_in && (
          <>
            <KBTooltip title={t('common.checkInTime')}>
              <KBText level={3} className="tw-text-gray-600">
                {formatTime(visitor?.signed_in_at!)}
              </KBText>
            </KBTooltip>
          </>
        )}

        {visitor?.unified_status === VisitorUnifiedStatusEnum.signed_out && (
          <>
            <KBTooltip title={t('common.checkOutTime')}>
              <KBText level={3} className="tw-text-gray-600">
                {formatTime(visitor?.signed_out_at!)}
              </KBText>
            </KBTooltip>
          </>
        )}

        <KBUser
          user={visitor?.host_user}
          className="tw-ml-0.5 tw-flex-shrink-0"
          showTooltip
        />

        <KBTooltip title={t('common.view')}>
          <KBButton
            type="text"
            icon={<KBIcon name="eye" />}
            onClick={(e) => {
              e.stopPropagation()
              gotoVisitorInfo()
            }}
            className="tw-w-9 tw-px-0"
          />
        </KBTooltip>

        {!readonly && (
          <KBFlex
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <KBDropdown
              trigger={['click']}
              placement="bottom"
              menu={{
                items: actionItems,
              }}
            >
              <KBButton
                type="text"
                className="tw-w-9 tw-px-0"
                icon={<KBIcon name="ellipsis" />}
              />
            </KBDropdown>
          </KBFlex>
        )}
      </KBFlex>
    </KBFlex>
  )
}

export default KBVisitorListItem
