import {
  ReservationShowEntity,
  ResourceEntity,
  TReservationReserveRule,
} from '@/api'
import { KBFlex, KBForm, KBIcon, KBText, KBTooltip } from '@/components/atoms'
import {
  FormValueProps,
  GroupEditType,
} from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'
import useCurrentLocationId from '@/hooks/useCurrentLocationId'
import { FormInstance } from 'antd'
import { useTranslation } from 'react-i18next'

export interface KBResourceReservationFormProps {
  resourcesList?: ResourceEntity[]
  selectedResourceList?: ResourceEntity[]
  reservationInfo?: ReservationShowEntity
  formInstance?: FormInstance<FormValueProps>
  groupEditType?: GroupEditType
  errorsMessages?: string
  isSingleResource?: boolean
}

const ResourceSelect: React.FC<KBResourceReservationFormProps> = (props) => {
  const { t } = useTranslation()
  const {
    resourcesList,
    selectedResourceList,
    reservationInfo,
    formInstance,
    groupEditType,
    errorsMessages,
    isSingleResource,
  } = props

  /**
   * 资源校验
   */
  /**
  const validateResource = () => {
    if (!selectedResourceList?.length) {
      return Promise.reject(new Error(tr('[intl]请选择资源')))
    }
    if (selectedResourceList.length > 1) {
      const notAllowGroupResources = selectedResourceList?.filter(
        (i) =>
          !(i.reservation_reserve_rule?.rules as TReservationReserveRule)
            ?.allow_group
      )
      if (notAllowGroupResources?.length) {
        return Promise.reject(
          new Error(
            `${notAllowGroupResources.map((i) => i.name)?.join('、')}${tr('[intl]不允许批量预定')}`
          )
        )
      }
    }
    return Promise.resolve()
  }
  */

  const resourceOptions = useMemo(
    () =>
      resourcesList?.map(({ id, name }: ResourceEntity) => ({
        label: name,
        value: id,
      })) || [],
    [resourcesList]
  )

  const disabled = useMemo(() => {
    if (isSingleResource) return true

    if (!reservationInfo) return false

    const { recurrence_rrule, status, checkin_at } = reservationInfo

    return (
      groupEditType === 'current' ||
      Boolean(recurrence_rrule) ||
      ['ongoing', 'ending_soon', 'terminated', 'cancelled'].includes(status) ||
      Boolean(checkin_at) ||
      isSingleResource
    )
  }, [reservationInfo, isSingleResource])

  const resourcesName = useMemo(() => {
    return selectedResourceList?.map((i) => i.name)?.join(', ')
  }, [selectedResourceList])

  const showGroupButton = useMemo(() => {
    return Boolean(
      selectedResourceList?.length &&
        (selectedResourceList || []).every((i) => {
          const rules = i.reservation_reserve_rule
            ?.rules as TReservationReserveRule
          return Boolean(rules?.allow_group)
        })
    )
  }, [selectedResourceList])

  const errors = useMemo(() => {
    if (!selectedResourceList?.length && resourcesList?.length) {
      return t('common.selectResource')
    }
    return errorsMessages
  }, [selectedResourceList, errorsMessages])

  const currentLocationId = useCurrentLocationId()

  return (
    <>
      <KBForm.Item
        label={
          <KBTooltip title={t('reservation.resource')}>
            <KBIcon size={24} name="presentation" />
          </KBTooltip>
        }
        validateStatus={errors ? 'error' : undefined}
        help={errors}
      >
        {/*
           <KBFlex align="center" gap={12}>
            <KBForm.Item
              name="resourceIds"
              // rules={[{ validator: validateResource }]}
              noStyle
              validateStatus={errors ? 'error' : undefined}
            >
              <KBSelect
                placeholder={tr('common.selectResource')}
                options={resourceOptions}
                className="tw-flex-1"
                mode="multiple"
                maxTagCount={'responsive'}
                disabled={true}
                showSearch
                optionFilterProp="label"
              />
            </KBForm.Item>
            {/* {showGroupButton && !disabled && (
              <KBButton
                type="link"
                className=" tw-p-0"
                onClick={() =>
                  NiceModal.show('reservation-form-batch-select-resource-modal', {
                    onSelectChange: (val: number[]) => {
                      console.log(val)
                      formInstance?.setFieldValue('resourceIds', val)
                    },
                    selectedKeys: formInstance?.getFieldValue('resourceIds'),
                    locationId: currentLocationId,
                  })
                }
              >
                <KBFlex align="center" gap={4}>
                  <KBIcon name="plus" size={18} />
                  {tr('reservation.multiple')}
                </KBFlex>
              </KBButton>
            )}
            <BatchSelectResourceModal id="reservation-form-batch-select-resource-modal" />
           </KBFlex>
           */}
        <KBFlex vertical gap={4} className="tw-pt-1.5">
          <KBText
            level={1}
            weight="bold"
            className=" tw-text-[var(--wox-color-text)]"
          >
            {resourcesName}
          </KBText>
          <KBText className=" tw-text-gray-500">
            {t('common.selectedResourceLength', {
              length: selectedResourceList?.length,
            })}
          </KBText>
        </KBFlex>
      </KBForm.Item>
    </>
  )
}

export default ResourceSelect
