import { KBSuspenseLoading } from '@/components/atoms/KBSuspenseLoading'
import dayjs from '@wox/dayjs'
import { ConfigProvider, ThemeConfig } from 'antd'
import { Locale } from 'antd/es/locale'
import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { localeAtom } from '../store'
import {
  ILocale,
  SUPPORTED_LOCALES,
} from '../utils/localization/supported_locales'

interface LocaleProviderProps {
  theme?: ThemeConfig
  children: React.ReactNode
}

export function LocaleProvider({ theme, children }: LocaleProviderProps) {
  const [locale] = useAtom(localeAtom)
  const [antdLocale, setAntdLocale] = useState<Locale | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const { i18n } = useTranslation()
  useEffect(() => {
    setIsLoading(true)
    i18n.changeLanguage(locale)

    const loadLocaleData = async () => {
      const localeData =
        SUPPORTED_LOCALES.find((l: ILocale) => l.value === locale) ||
        SUPPORTED_LOCALES.find((l: ILocale) => l.value === 'en-US')!

      const intlResult = await localeData.intl()
      const dayjsResult = await localeData.dayjs()
      const antDResult = await localeData.antD()
      setAntdLocale(antDResult.default)
      dayjs.locale(dayjsResult.default)
      i18n.changeLanguage(localeData.value)
      setIsLoading(false)

      // intl
      //   .init({
      //     currentLocale: locale,
      //     locales: {
      //       [locale]: intlResult.default,
      //     },
      //     warningHandler: (message, error) => {
      //       // TODO: suppress warnings before intl is completed
      //       // if (
      //       //   message.includes('not defined') ||
      //       //   message.includes('not exists')
      //       // ) {
      //       //   return
      //       // }
      //       // console.warn(message)
      //     },
      //   })
      //   .then(() => {
      //     setIsLoading(false)
      //   })
    }
    loadLocaleData()
  }, [locale])
  if (isLoading) {
    return <KBSuspenseLoading />
  }
  return (
    <ConfigProvider locale={antdLocale} theme={theme}>
      {children}
    </ConfigProvider>
  )
}
