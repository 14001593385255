import { ReservationShowEntity, ResourceEntity } from '@/api'
import { KBForm, KBIcon, KBInput, KBTooltip } from '@/components/atoms'
import { GroupEditType } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'
import { useTranslation } from 'react-i18next'

/**
 * 云会议选择
 */
export interface CloudVideoValueProps {
  joinUrl?: string
  meetingProvider?: string
}
interface CloudVideoSelectProps {
  value?: CloudVideoValueProps
  resourcesList: ResourceEntity[]
  reservationInfo?: ReservationShowEntity
  groupEditType?: GroupEditType
  errorsMessages?: string
  onChange?: (value?: CloudVideoValueProps) => void
}

/**
 * 云会议选择组件
 */
const CloudVideoSelect: React.FC<CloudVideoSelectProps> = (props) => {
  const { t } = useTranslation()
  const {
    resourcesList,
    reservationInfo,
    groupEditType,
    errorsMessages,
    onChange,
  } = props

  /**
   * 云会议校验
   */
  /**
  const validateCloudVideo = (
    cloudVideoProviders: FormValueProps['cloudVideoProviders']
  ) => {
    if (!cloudVideoProviders) return Promise.resolve()
     const { joinUrl, meetingProvider } = cloudVideoProviders
    // 多个资源是否都有xx选项
    if (meetingProvider) {
      if (meetingProvider !== 'unknown') {
        const cannotSelectCloudVideoProviders = resourcesList.filter(
          (i) =>
            !(
              i.reservation_form_rule?.rules as TReservationFormRule
            )?.cloud_video_app?.includes(meetingProvider)
        )
        if (cannotSelectCloudVideoProviders?.length) {
          return Promise.reject(
            new Error(
              `${cannotSelectCloudVideoProviders.map((i) => i.name).join('、')}${tr('[intl]不允许选择')}${meetingProvider}`
            )
          )
        } else {
          return Promise.resolve()
        }
      } else {
        // 自定义输入--校验自定义链接必填
        const cannotInputCloudVideoProviders = resourcesList.filter(
          (i) =>
            !(i.reservation_form_rule?.rules as TReservationFormRule)
              ?.connection_by_self
        )
        if (cannotInputCloudVideoProviders?.length) {
          return Promise.reject(
            new Error(
              `${cannotInputCloudVideoProviders.map((i) => i.name).join('、')}${tr('[intl]不允许自定义云会议链接')}`
            )
          )
        } else if (joinUrl) {
          return Promise.resolve()
        } else {
          return Promise.reject(new Error(tr('[intl]请输入云会议链接')))
        }
      }
    } else {
      return Promise.resolve()
    }
  }
  */

  const showCloudVideo = useMemo(() => {
    return (resourcesList || []).some((i) => {
      const rules = i.reservation_reserve_rule?.rules
      return rules?.connection_by_self
    })
  }, [resourcesList])

  const disabled = useMemo(() => {
    if (!reservationInfo) return false

    const { is_group } = reservationInfo

    return groupEditType === 'current'
  }, [reservationInfo])

  return showCloudVideo ? (
    <KBForm.Item
      name={'cloudVideoProviders.joinUrl'.split('.')}
      label={
        <KBTooltip title={t('common.cloudMeeting')}>
          <KBIcon name="video" size={24} />
        </KBTooltip>
      }
      dependencies={['resourceIds']}
      // rules={[
      //   {
      //     validator: (_, cloudVideoProviders) =>
      //       validateCloudVideo(cloudVideoProviders),
      //   },
      // ]}
      validateStatus={Boolean(errorsMessages?.length) ? 'error' : undefined}
      help={errorsMessages}
    >
      <KBInput placeholder={t('cloudMeeting.customLink')} disabled={disabled} />
    </KBForm.Item>
  ) : null
}

export default CloudVideoSelect
