import { LocationEntity } from '@/api'
import { TimeFormatEnum } from '@/enums/modelEnums'
import dayjs, { FormatOptions, formatStorage } from '@wox/dayjs'
import { atom } from 'jotai'
import { atomEffect } from 'jotai-effect'

/**
 * 获取当前分店
 */
export const locationAtom = atom<LocationEntity | undefined>(undefined)

export const dateFormatEffect = atomEffect((get, _) => {
  // runs on mount or whenever locationAtom changes
  const currentLocation = get(locationAtom)
  if (currentLocation) {
    const formatOptions: FormatOptions = {
      dateFormat: currentLocation.date_format,
      timeFormat:
        currentLocation.time_format === TimeFormatEnum.Twelve ? 12 : 24,
      timeZone: dayjs.tz.guess(), //currentLocation.time_zone,
      locale: currentLocation.language,
      // weekdayFormat: location.weekday_format,
      firstDayOfWeek: currentLocation.first_day_of_week,
    }
    console.log('set formatOptions', formatOptions)
    formatStorage.set(formatOptions)
  }
})
