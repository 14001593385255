import { KBDatePicker } from '@/components/atoms/KBDatePicker'
import { KBIcon } from '@/components/atoms/KBIcon'
import { KBSelectProps } from '@/components/atoms/KBSelect'
import { KBSpace } from '@/components/atoms/KBSpace'
import { KBTimeSelectPicker } from '@/components/atoms/KBTimeSelectPicker'
import dayjs, { Dayjs, findClosestTimeWithStep, setToDate } from '@wox/dayjs'

export interface KBDateTimeRangeSelectProps
  extends Omit<KBSelectProps, 'value' | 'onChange' | 'mode'> {
  onChange?: (startTime?: Dayjs, endTime?: Dayjs) => void
  step?: number
  value?: [Dayjs | undefined, Dayjs | undefined]
  /**
   * 日期选择器禁用日期
   */
  disabledDate?: (date: Dayjs) => boolean
  /**
   * 开始时间选择器禁用时间
   */
  startDisabledTime?: (date: Dayjs) => boolean
  /**
   * 结束时间选择器禁用时间
   */
  endDisabledTime?: (date: Dayjs) => boolean
  /**
   * 隐藏日期选择器, 只选择时间范围
   */
  hideDatePicker?: boolean
  /**
   * 隐藏时间选择器
   */
  hideTimePicker?: boolean
  /**
   * 隐藏结束日期选择器，如果开始日期和结束日期是同一天
   */
  hideEndDateIfSameAsStartDate?: boolean

  /**
   * 开始时间是否使用当前时间
   */
  startUseCurrentTime?: boolean
  /**
   * 结束时间是否使用当前时间
   */
  endUseCurrentTime?: boolean
}
/**
 * 选择任意时间段，包括日 期选择和时间选择
 *
 * @param props
 * @returns
 */
export function KBDateTimeRangeSelect(props: KBDateTimeRangeSelectProps) {
  const {
    onChange,
    value,
    step = 30,
    className,
    allowClear = false,
    disabled,
    variant,
    disabledDate,
    startDisabledTime,
    endDisabledTime,
    hideDatePicker,
    hideTimePicker,
    hideEndDateIfSameAsStartDate = true,
    startUseCurrentTime,
    endUseCurrentTime,
    ...restProps
  } = props

  const [startTime, setStartTime] = useState<Dayjs>(
    startUseCurrentTime ? dayjs() : findClosestTimeWithStep(dayjs(), step)
  )
  const [endTime, setEndTime] = useState<Dayjs>(
    endUseCurrentTime
      ? dayjs()
      : findClosestTimeWithStep(dayjs().add(1, 'hour'), step)
  )

  useEffect(() => {
    // console.log('>>>value', value?.[0]?.format())
    const start = !startUseCurrentTime
      ? findClosestTimeWithStep(value?.[0] || dayjs(), step)
      : value?.[0] || dayjs()
    const end = !endUseCurrentTime
      ? findClosestTimeWithStep(value?.[1] || dayjs(), step)
      : value?.[1] || dayjs()
    setStartTime(start)
    setEndTime(end)
  }, [value, step])

  const handleChange = (newStartTime: Dayjs, newEndTime: Dayjs) => {
    if (hideTimePicker) {
      onChange?.(newStartTime.startOf('day'), newEndTime.endOf('day'))
    } else {
      onChange?.(newStartTime, newEndTime)
    }
  }

  const handleStartDateChange = (date: Dayjs) => {
    let newStartTime: Dayjs = setToDate(startTime, date)
    if (startDisabledTime?.(newStartTime)) {
      newStartTime = dayjs()
    }
    setStartTime(newStartTime)
    let newEndTime = endTime
    if (newStartTime.isSameOrAfter(endTime)) {
      const duration = endTime.diff(
        findClosestTimeWithStep(startTime, step),
        'minute'
      )
      newEndTime = newStartTime.add(duration, 'minute')
    }
    setEndTime(newEndTime)
    handleChange(newStartTime, newEndTime)
  }

  const handleEndDateChange = (date: Dayjs) => {
    let newEndTime: Dayjs = setToDate(endTime, date)
    if (newEndTime.isSameOrBefore(startTime, 'minute')) {
      newEndTime = startTime.add(step, 'minute')
    }
    if (endDisabledTime?.(newEndTime)) {
      newEndTime = dayjs().add(step, 'minute')
    }
    setEndTime(newEndTime)
    let newStartTime = startTime
    if (newEndTime.isSameOrBefore(startTime)) {
      newStartTime = newEndTime.subtract(step, 'minute')
      setStartTime(newStartTime)
    }
    handleChange(newStartTime, newEndTime)
  }

  const handleStartTimeChange = (date: Dayjs) => {
    let newStartTime: Dayjs = date
    if (startDisabledTime?.(newStartTime)) {
      newStartTime = dayjs()
    }
    setStartTime(newStartTime)
    let newEndTime = endTime
    if (newStartTime.isSameOrAfter(endTime)) {
      const duration = endTime.diff(
        findClosestTimeWithStep(startTime, step),
        'minute'
      )
      newEndTime = newStartTime.add(duration, 'minute')
    }
    setEndTime(newEndTime)
    handleChange(newStartTime, newEndTime)
  }

  const handleEndTimeChange = (date: Dayjs) => {
    let newEndTime: Dayjs = date
    // console.log('newEndTime', newEndTime.format())
    if (newEndTime.isSameOrBefore(startTime, 'minute')) {
      newEndTime = startTime.add(step, 'minute')
    }
    if (endDisabledTime?.(newEndTime)) {
      newEndTime = dayjs().add(step, 'minute')
    }
    setEndTime(newEndTime)
    let newStartTime = startTime
    if (newEndTime.isSameOrBefore(startTime)) {
      newStartTime = newEndTime.subtract(step, 'minute')
      setStartTime(newStartTime)
    }
    handleChange(newStartTime, newEndTime)
  }

  const isSameDate = startTime?.isSame(endTime, 'day')
  // console.log('startTime', startUseCurrentTime, startTime.format())
  // console.log('endTime', endTime.format())
  return (
    <KBSpace wrap className="tw-flex-shrink-0">
      {!hideDatePicker && (
        <KBDatePicker
          className="tw-w-32"
          value={startTime}
          onChange={handleStartDateChange}
          disabledDate={disabledDate}
          allowClear={false}
          variant={variant}
        />
      )}
      {!hideTimePicker && (
        <KBTimeSelectPicker
          data-testid="start-time-picker"
          value={startTime}
          onChange={handleStartTimeChange}
          disabledTime={startDisabledTime}
          useCurrentTime={startUseCurrentTime}
          step={step}
          variant={variant}
          allowClear={false}
          {...restProps}
        />
      )}
      <KBIcon
        name="minus"
        className="tw-text-[var(--wox-color-text-quaternary)]"
      />
      {hideDatePicker ||
      (!hideTimePicker && hideEndDateIfSameAsStartDate && isSameDate) ? null : (
        <KBDatePicker
          className="tw-w-32"
          value={endTime}
          allowClear={false}
          onChange={handleEndDateChange}
          disabledDate={(date) => date.isBefore(startTime, 'day')}
          variant={variant}
        />
      )}
      {!hideTimePicker && (
        <KBTimeSelectPicker
          data-testid="end-time-picker"
          value={endTime}
          onChange={handleEndTimeChange}
          useCurrentTime={endUseCurrentTime}
          disabledTime={(date) =>
            date.isSameOrBefore(startTime.add(step, 'minute'), 'minute')
          }
          step={step}
          variant={variant}
          allowClear={false}
          {...restProps}
        />
      )}
    </KBSpace>
  )
}
