import { cn } from '@/utils/tailwind.helper'
import dayjs, { Dayjs, formatStorage } from '@wox/dayjs'
import { DatePicker } from 'antd'
import { PickerPropsWithMultiple } from 'antd/es/date-picker/generatePicker/interface'

export interface KBDatePickerProps extends PickerPropsWithMultiple {}

export function KBDatePicker(props: KBDatePickerProps) {
  const { size = 'large', className, value, onChange, ...others } = props
  const dateFormat = formatStorage.get()?.dateFormat || 'YYYY-MM-DD'
  const realValue = Array.isArray(value) ? value : dayjs(value)

  return (
    <DatePicker
      size={size}
      value={realValue}
      data-testid="date-picker"
      inputReadOnly // Prevent keyboard input
      format={dateFormat}
      {...others}
      className={cn(size === 'large' && 'tw-h-9', 'kb-date-picker', className)}
      onChange={(date, dateString) => {
        let formattedDate = date
        if (date?.$isDayjsObject) {
          // 点击日期面板下的“今天”时，antd会返回一个dayjs对象，这个对象没有继承插件的方法，所以这里用自己的dayjs重新转一下
          formattedDate = dayjs((date as Dayjs).toDate())
        }
        onChange?.(formattedDate, dateString)
      }}
    />
  )
}

KBDatePicker.RangePicker = DatePicker.RangePicker
