import { useTranslation } from 'react-i18next'
import { KBButton, KBFlex } from '@/components/atoms'
import { RecurrenceByYearInterval } from '@/components/organisms/KBRecurrenceEditModal/constants'
import {
  KBRecurrenceEditModalProps,
  RecurrenceInfo,
} from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceEditModal'
import { localeAtom } from '@/store'
import dayjs, { nameOfDay } from '@wox/dayjs'
import { useAtom } from 'jotai'
import { FunctionComponent } from 'react'

interface KBRecurrenceByMonthProps extends KBRecurrenceEditModalProps {
  recurrenceInfo: RecurrenceInfo
  setRecurrenceInfo: (info: RecurrenceInfo) => void
}

const KBRecurrenceByMonth: FunctionComponent<KBRecurrenceByMonthProps> = (
  props
) => {
  const { t } = useTranslation()
  const { recurrenceInfo, setRecurrenceInfo } = props
  const startTime = recurrenceInfo.startDate || dayjs()
  const [locale] = useAtom(localeAtom)

  return (
    <KBFlex justify="flex-start" className="tw-mt-3 tw-pl-[88px]" gap={6}>
      <KBButton
        type={
          recurrenceInfo.yearRecurrenceByYearInterval ===
          RecurrenceByYearInterval.day
            ? 'primary'
            : 'text'
        }
        className={
          recurrenceInfo.yearRecurrenceByYearInterval ===
          RecurrenceByYearInterval.day
            ? ''
            : 'tw-bg-[var(--wox-color-fill-tertiary)]'
        }
        onClick={() => {
          // setAllValues({
          //   ...allValues,
          //   monthDay: startTime.date(),
          //   monthWeekNumber: undefined,
          //   monthWeekDay: undefined,
          // })
          setRecurrenceInfo({
            ...recurrenceInfo,
            monthDay: startTime.date(),
            monthWeekNumber: undefined,
            monthWeekDay: undefined,
            month: startTime.month() + 1,
            intervalType: recurrenceInfo.intervalType,
            yearRecurrenceByYearInterval: RecurrenceByYearInterval.day,
          })
        }}
      >
        {t('recurrence.yearMonthDay', {
          month: startTime.month() + 1,
          date: startTime.date(),
        })}
      </KBButton>
      <KBButton
        type={
          recurrenceInfo.yearRecurrenceByYearInterval ===
          RecurrenceByYearInterval.week
            ? 'primary'
            : 'text'
        }
        className={
          recurrenceInfo.yearRecurrenceByYearInterval ===
          RecurrenceByYearInterval.week
            ? ''
            : 'tw-bg-[var(--wox-color-fill-tertiary)]'
        }
        onClick={() => {
          setRecurrenceInfo({
            ...recurrenceInfo,
            monthDay: undefined,
            monthWeekNumber:
              startTime.isoWeek() - startTime.startOf('month').isoWeek(),
            monthWeekDay: startTime.isoWeekday() - 1,
            month: startTime.month() + 1,
            intervalType: recurrenceInfo.intervalType,
            yearRecurrenceByYearInterval: RecurrenceByYearInterval.week,
          })
        }}
      >
        {t('recurrence.yearMonthWeekDay', {
          month: startTime.month() + 1,
          no: startTime.isoWeek() - startTime.startOf('month').isoWeek(),
          weekDay: nameOfDay(startTime.weekday(), {
            weekdayFormat: 'short',
            locale,
          }),
        })}
      </KBButton>
    </KBFlex>
  )
}

export default KBRecurrenceByMonth
