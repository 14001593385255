import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  ReservationShowEntity,
  ResourceEntity,
  useCustomFieldFindMany,
} from '@/api'
import {
  KBDivider,
  KBForm,
  KBIcon,
  KBText,
  KBTooltip,
} from '@/components/atoms'
import KBCustomFieldFormItem from '@/components/molecules/KBCustomFieldFormItem/KBCustomFieldFormItem'
import { GroupEditType } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'
import { CustomFieldOwnerTypeEnum } from '@/enums/modelEnums'
import styles from './customFields.module.css'

interface CustomFieldsProps {
  resourcesList: ResourceEntity[]
  groupEditType?: GroupEditType
  reservationInfo?: ReservationShowEntity
}
/**
 * 自定义字段组件
 */
const CustomFields: React.FC<CustomFieldsProps> = (props) => {
  const { t } = useTranslation()
  const { resourcesList, groupEditType, reservationInfo } = props
  const formRuleIds = resourcesList
    .filter((r) => r.reservation_form_rule?.id)
    .map((r) => r.reservation_form_rule?.id!)
  const { data: customFields } = useCustomFieldFindMany(
    {
      owner_type: CustomFieldOwnerTypeEnum.ReservationPolicyType,
      owner_ids: formRuleIds,
    },
    { query: { enabled: !!formRuleIds?.length } }
  )
  const resourcesWithCustomFields = useMemo(() => {
    return resourcesList
      .map((i) => {
        return {
          ...i,
          custom_fields: customFields?.filter(
            (customField) =>
              customField.owner_id === i.reservation_form_rule?.id
          ),
        }
      })
      .filter(Boolean)
  }, [resourcesList, customFields])

  const showCustomFields = useMemo(() => {
    return resourcesList.some((i) =>
      Boolean(
        customFields?.find(
          (customField) => customField.owner_id === i.reservation_form_rule?.id
        )
      )
    )
  }, [resourcesList, customFields])

  const disabled = useMemo(() => {
    if (!reservationInfo) return false

    const { is_group } = reservationInfo

    return groupEditType === 'current'
  }, [reservationInfo])

  return showCustomFields ? (
    <>
      <KBDivider className=" tw-my-8" />
      <KBForm.Item
        label={
          <KBTooltip title={t('common.customFields')}>
            <KBIcon name="file-pen" size={24} />
          </KBTooltip>
        }
      >
        {resourcesWithCustomFields
          .filter((i) => Boolean(i.custom_fields?.length))
          .map((resource) => {
            return (
              <>
                {resource.custom_fields?.map((field) => {
                  return (
                    <KBCustomFieldFormItem
                      key={field.id}
                      wrapperCol={{ flex: '100%' }}
                      customField={field}
                      disabled={disabled}
                      className={styles['reservation-form-form-item']}
                      customLabel={
                        <>
                          {field.label}
                          <KBText className="tw-text-gray-500">
                            ({resource.name})
                          </KBText>
                        </>
                      }
                      label={field.label}
                      name={`customFieldsValue.${resource.id}__customField__${field.id}`.split(
                        '.'
                      )}
                    />
                  )
                })}
              </>
            )
          })}
      </KBForm.Item>
    </>
  ) : null
}

export default CustomFields
