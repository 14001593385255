import { useTranslation } from 'react-i18next'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import { useResourceIndex } from '@/api'
import {
  KBButton,
  KBFlex,
  KBIcon,
  KBModal,
  KBTypography,
} from '@/components/atoms'
import { formatDateTimeRange } from '@wox/dayjs'
interface KBApprovalModalProps {
  start_at?: string
  end_at?: string
  resourceId?: number
}

function KBApprovalModal(props: KBApprovalModalProps) {
  const { t } = useTranslation()
  const { resourceId, start_at, end_at } = props
  const modal = useModal()
  const params = useParams()

  const { data } = useResourceIndex({ page: 1, limit: 1, id: resourceId })
  const nameArray = [
    data?.result?.[0]?.name,
    data?.result?.[0]?.floor_info?.layer_description,
  ]

  return (
    <KBModal
      modal={modal}
      title={t('resource.resourceReservation')}
      footer={null}
      width={384}
    >
      <>
        <KBFlex
          vertical
          gap={16}
          align="center"
          justify="center"
          className="tw-mb-8"
        >
          <KBIcon
            name="clock"
            size={90}
            className="tw-m-[20px] tw-text-[var(--wox-color-warning-hover)]"
          />

          <KBFlex vertical align="center" justify="center" gap={4}>
            <KBTypography.Text className="tw-text-2xl tw-font-bold">
              {t('common.approvalModalText')}
            </KBTypography.Text>
            <KBFlex vertical gap={0}>
              <KBTypography.Text className="tw-text-center tw-text-lg tw-font-bold">
                {formatDateTimeRange([start_at!, end_at!])}
              </KBTypography.Text>
              <KBTypography.Text className="tw-text-center tw-text-lg tw-font-bold">
                {nameArray.join(', ')}
              </KBTypography.Text>
            </KBFlex>
          </KBFlex>
        </KBFlex>
        <KBButton
          type="primary"
          className="tw-w-full"
          onClick={() => modal.hide()}
        >
          {t('common.signSuccessDesc')}
        </KBButton>
      </>
    </KBModal>
  )
}

export default NiceModal.create(KBApprovalModal)
