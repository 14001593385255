import { useTranslation } from 'react-i18next'
import { KBAvatar, KBFlex, KBPopover, KBTypography } from '@/components/atoms'
import { KBUserAvatar } from '@/components/organisms'
import { getUserAvatar } from '@/utils/avatarUrl'
import { theme } from 'antd'
import { ReactNode } from 'react'

export interface KBUserPopoverProps {
  user?: KBUserAvatar
  children: ReactNode | string
  toolTipContent?: React.ReactNode
}
export function KBUserPopover({
  user,
  children,
  toolTipContent,
}: KBUserPopoverProps) {
  const { t } = useTranslation()
  const { useToken } = theme
  const { token } = useToken()
  const content = (
    <KBFlex vertical>
      <KBFlex gap={8}>
        <KBAvatar
          size={32}
          src={getUserAvatar(user)}
          className="tw-flex-shrink-0"
        />

        <KBFlex vertical gap={0}>
          <KBFlex gap={4}>
            <KBTypography.Text className="tw-font-medium">
              {user?.name}
            </KBTypography.Text>
            {user?.department?.name && (
              <KBTypography.Text className="tw-text-[var(--wox-color-text-quaternary)]">
                ({user?.department?.name})
              </KBTypography.Text>
            )}
          </KBFlex>
          <KBTypography.Text className="tw-text-xs tw-text-[var(--wox-color-text-description)]">
            {user?.email ? user?.email : t('common.noEmail')}
          </KBTypography.Text>
        </KBFlex>
      </KBFlex>

      {toolTipContent}
    </KBFlex>
  )

  return (
    <KBPopover
      content={content}
      arrow={false}
      overlayInnerStyle={{
        border: `1px solid ${token.colorBorderSecondary}`,
      }}
      align={{
        offset: [0, -12],
      }}
    >
      {children}
    </KBPopover>
  )
}
