import { useTranslation } from 'react-i18next'
import {
  useLocationAdminFindById,
  useVisitorConfigAdminEnableVisitorConfigByLocation,
} from '@/api'
import { KBButton, KBIcon } from '@/components/atoms'
import { kbMessage } from '@/components/atoms/KBMessageGlobal'
import { KBSuspenseLoading } from '@/components/atoms/KBSuspenseLoading'
import KBSubscribedFeature from '@/components/molecules/KBSubscribedFeature/KBSubscribedFeature'
import KBUpgrade from '@/components/molecules/KBUpgrade/KBUpgrade'
import { FeatureNameEnum } from '@/enums/modelEnums'
import useCurrentLocationId from '@/hooks/useCurrentLocationId'
import React from 'react'
import { Outlet } from 'react-router-dom'

export const VisitorLayout: React.FC = () => {
  const { t } = useTranslation()
  const currentLocationId = useCurrentLocationId()
  const {
    data: location,
    isLoading,
    refetch,
  } = useLocationAdminFindById(currentLocationId!)
  const { mutate: enableVisitor, isPending } =
    useVisitorConfigAdminEnableVisitorConfigByLocation()

  if (isLoading) {
    return <KBSuspenseLoading />
  }

  if (!location?.visitor_enabled) {
    return (
      <KBUpgrade
        title={t('common.visitorCheckIn')}
        subtitle={t('common.visitorNotSubscribedTitle')}
        description={t('common.visitorNotSubscribedDescription')}
        button={
          <KBButton
            type="primary"
            loading={isPending}
            onClick={() => {
              enableVisitor(
                {
                  params: {
                    location_id: currentLocationId!,
                  },
                },
                {
                  onSuccess: () => {
                    kbMessage.success(t('common.updateSuccess'))
                    refetch()
                  },
                }
              )
            }}
            className="tw-mt-2"
            icon={<KBIcon name="mail" />}
          >
            {t('common.enableVisitor')}
          </KBButton>
        }
      />
    )
  }

  return (
    <KBSubscribedFeature
      featureName={FeatureNameEnum.Visitor}
      fallback={
        <KBUpgrade
          title={t('common.visitorCheckIn')}
          subtitle={t('common.visitorNotSubscribedTitle')}
          description={t('common.visitorNotSubscribedDescription')}
        />
      }
    >
      <Outlet />
    </KBSubscribedFeature>
  )
}
