import { Flex, Layout } from 'antd'
import { cva } from 'class-variance-authority'
import { ArrowLeft } from 'lucide-react'
import { ReactNode } from 'react'

import { IconName, KBButton, KBFlex, KBIcon, KBTitle } from '@/components/atoms'
import { cn } from '@/utils/tailwind.helper'
import { isAdminClient } from '@/utils/userEnvironment'
import { motion } from 'framer-motion'

const { Header, Content } = Layout

export interface PageContentLayoutProps {
  children: ReactNode
  extra?: ReactNode
  subTitle?: string
  title: string | ReactNode
  /**
   * 是否显示返回按钮
   */
  showBackButton?: boolean
  /**
   * 页面类型
   */
  pageType?:
    | 'infoPage'
    | 'transparentBgPage'
    | 'tabPage'
    | 'infoTabPage'
    | 'baseInfoPage'
    | 'borderless'
  icon?: IconName
  /**
   * 这里的layout默认高度是100vh，autoHeight=false即可自适应高度
   */
  autoHeight?: boolean
}

const layoutClass = cva(
  `${isAdminClient() ? 'tw-min-w-[736px]' : 'tw-min-w-[866px]'} `,
  {
    variants: {
      pageType: {
        infoPage:
          'tw-pl-6 tw-mx-auto tw-w-full tw-max-w-[896px] tw-box-border tw-pr-6 tw-h-screen',
        transparentBgPage:
          'tw-pl-6 tw-overflow-auto tw-h-screen tw-bg-[var(--wox-color-bg-layout)]',
        tabPage: 'tw-pl-6 tw-bg-[var(--wox-color-bg-layout)]',
        infoTabPage:
          'tw-pl-6 tw-mx-auto tw-w-full tw-max-w-[808px] tw-pr-6 tw-h-screen',
        baseInfoPage:
          'tw-pl-6 tw-mx-auto tw-w-full tw-bg-[var(--wox-color-bg-layout)] tw-max-w-[792px]',
        borderless:
          'tw-bg-[var(--wox-color-bg-layout)] tw-mx-auto tw-w-full tw-box-border',
      },
    },
  }
)

const headerClass = cva(
  'tw-bg-transparent   tw-h-8  tw-ml-0 tw-py-5 tw-px-0 tw-box-content tw-bg-[var(--wox-color-color-bg-container)]',
  {
    variants: {
      pageType: {
        infoPage:
          ' tw-border tw-border-x-0 tw-border-t-0 tw-border-solid tw-border-[var(--wox-color-border-secondary)]',
        transparentBgPage:
          ' tw-border tw-border-x-0 tw-border-t-0 tw-border-solid tw-border-[var(--wox-color-border-secondary)] tw-h-unset tw-mr-6',
        tabPage: 'tw-pb-3 tw-mr-6',
        infoTabPage: 'tw-mx-auto ',
        baseInfoPage:
          'tw-border tw-py-6 tw-border-x-0 tw-border-t-0 tw-border-solid tw-border-[var(--wox-color-border-secondary)]',
        borderless: ' tw-pt-0 tw-pb-2 tw-border-0  tw-h-unset ',
      },
    },
  }
)

const contentContainerClass = cva('', {
  variants: {
    pageType: {
      infoPage: 'tw-pb-0',
      transparentBgPage: '',
      baseInfoPage: 'tw-pb-0',
      tabPage: 'tw-pr-6',
      infoTabPage: 'tw-pb-0',
      borderless: '',
    },
  },
})

const contentClass = cva(
  'tw-rounded-lg tw-bg-[var(--wox-color-bg-container)]',
  {
    variants: {
      pageType: {
        infoPage: 'tw-bg-transparent ',
        transparentBgPage: 'tw-bg-transparent tw-py-6 tw-pr-6',
        tabPage: '',
        infoTabPage: '',
        baseInfoPage: '',
        borderless: '',
      },
    },
  }
)

export default function PageContentLayout({
  children,
  extra,
  title,
  pageType = 'transparentBgPage',
  showBackButton = pageType === 'infoPage',
  autoHeight = false,
  icon,
}: PageContentLayoutProps) {
  const navigate = useNavigate()
  const outClassName = cn(
    'tw-w-full tw-overflow-auto',
    autoHeight ? '' : 'tw-h-screen'
  )
  return (
    // 3rem是header高度

    <KBFlex className={outClassName}>
      <Layout className={layoutClass({ pageType })}>
        <Header className={headerClass({ pageType })}>
          <Flex justify="space-between" align="center">
            <Flex align="center" gap={8} className="tw-h-9">
              {showBackButton && (
                <KBButton
                  type="text"
                  size="middle"
                  shape="circle"
                  className="tw-px-1"
                  onClick={() => {
                    window.history.back()
                    // if (window.history?.state?.url) {
                    //   // let previousUrl = null
                    //   // for (let i = 0; i < window.history.length; i++) {
                    //   //   const state = (
                    //   //     (window.history as any)[i] as unknown as {
                    //   //       url: string
                    //   //     }
                    //   //   )?.state
                    //   //   if (state !== null) {
                    //   //     previousUrl = state.url
                    //   //     break
                    //   //   }
                    //   // }
                    //   // console.log(previousUrl)
                    //   // if (previousUrl) {
                    //   //   window.location.replace(previousUrl)
                    // } else {
                    //   if (isAdminClient()) {
                    //     navigate('/admin/dashboard')
                    //   } else {
                    //     navigate('/user/dashboard')
                    //   }
                    // }
                  }}
                  icon={<ArrowLeft size={18} color="var(--wox-color-text) " />}
                />
              )}
              {icon && <KBIcon name={icon} size={24} />}
              <KBTitle level={3}>{title}</KBTitle>
            </Flex>
            {extra}
          </Flex>
        </Header>
        {/* {pageType !== 'tabPage' && <KBDivider className="tw-my-0 tw-pr-6" />} */}
        <Content className={contentContainerClass({ pageType })}>
          <div className={contentClass({ pageType })}>
            <motion.div
              initial={{ opacity: 0, scale: 0.995 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.995 }}
              transition={{ duration: 0.1 }}
            >
              {children}
            </motion.div>
          </div>
        </Content>
      </Layout>
    </KBFlex>
  )
}
