import { SimpleDepartmentEntity } from '@/api'
import { KBFlex, KBSpace, KBText, KBTypography } from '@/components/atoms'
import { KBAvatar } from '@/components/atoms/KBAvatar'
import { KBUserPopover } from '@/components/molecules/KBUserPopover'
import useCurrentLocationId from '@/hooks/useCurrentLocationId'
import { getUserAvatar } from '@/utils/avatarUrl'
import { cn } from '@/utils/tailwind.helper'
import { isUserClient } from '@/utils/userEnvironment'
const { Text } = KBTypography
export type AvatarSize = number | 'small' | 'default' | 'large'

export interface KBUserAvatar {
  id?: number
  name?: string
  avatar?: string
  email?: string
  department?: SimpleDepartmentEntity
  company?: string
  nickname?: string
}

export interface KBUserProps {
  user?: KBUserAvatar
  size?: AvatarSize
  showName?: boolean
  showDepartment?: boolean
  showEmail?: boolean
  showNickname?: boolean
  /**
   * 是否可点击查看用户，默认为 false
   */
  clickable?: boolean
  /**
   * 是否显示 tooltip, 默认为 true, 显示则鼠标 hover 时显示用户名
   */
  showTooltip?: boolean

  /**
   * 子元素
   */
  children?: React.ReactNode

  /**
   * tooltip 内容
   */
  toolTipContent?: React.ReactNode
  /**
   * 头像和名字的距离
   */
  gapNumber?: number
  /**
   * 自定义样式
   */
  className?: string
  /**
   * 自定义用户名样式
   */
  textClassName?: string
}

export function KBUser({
  user,
  size = 32,
  showTooltip = true,
  showDepartment = false,
  showEmail = false,
  showNickname = false,
  showName = true,
  clickable = false,
  toolTipContent,
  gapNumber = 8,
  className,
  textClassName,
  children,
}: KBUserProps) {
  if (!user) {
    return <KBText>-</KBText>
  }
  const navigate = useNavigate()
  const currentLocationId = useCurrentLocationId()
  const goToUserUrl = isUserClient()
    ? `/user/users/${user.id}`
    : currentLocationId
      ? `/admin/locations/${currentLocationId}/users/${user.id}`
      : `/admin/users/${user.id}`

  const element = (
    <KBSpace size={gapNumber} align="center">
      <KBAvatar size={size} src={getUserAvatar(user, size)} />
      <KBFlex vertical justify="center" gap={2}>
        <KBFlex gap={4} align="center">
          {showName && (
            <Text
              className={cn(
                'tw-cursor-pointer tw-font-medium tw-leading-none',
                clickable && 'tw-text-[var(--wox-color-primary)]',
                textClassName
              )}
              onClick={(e) => {
                if (clickable) {
                  e.stopPropagation()
                  navigate(goToUserUrl)
                }
              }}
            >
              {user?.name}
            </Text>
          )}
          {children}
          {showNickname && user.nickname && (
            <Text type="secondary" className="tw-font-normal">
              {`(${user.nickname})`}
            </Text>
          )}
        </KBFlex>
        {showDepartment && 'department' in user && (
          <Text type="secondary" className="tw-font-normal">
            {user.department?.name}
          </Text>
        )}
        {showEmail && 'email' in user && (
          <Text type="secondary" className="tw-text-xs tw-font-normal">
            {user.email}
          </Text>
        )}
      </KBFlex>
    </KBSpace>
  )

  return (
    <KBFlex className={className}>
      {showTooltip ? (
        <KBUserPopover user={user} toolTipContent={toolTipContent}>
          {element}
        </KBUserPopover>
      ) : (
        element
      )}
    </KBFlex>
  )
}
