import { AvatarSize, KBUserAvatar } from '@/components/organisms'
import { CLOUDFLARE_IMAGE_URL } from '@/utils/constants'

const AVATAR_SIZES: Record<AvatarSize, number> = {
  small: 32,
  default: 64,
  large: 200,
}
/**
 * 获取用户的avatar url
 * @param param0
 * @returns
 */
export function getUserAvatar(avatarType?: KBUserAvatar, size?: AvatarSize) {
  const { avatar, name } = avatarType || { name: 'Unknown' }
  const getAvatarSize = (size: AvatarSize): number => {
    if (typeof size === 'number') {
      if (size <= 32) return 32
      if (size <= 64) return 64
      return 200
    }
    return AVATAR_SIZES[size] || AVATAR_SIZES.default
  }

  const avatarSize = getAvatarSize(size || 32)

  const getCloudflareImageUrl = (url: string, size: number) => {
    // MIGRATE: 这是因为旧版的图片URL使用的是r2.dev, 需要替换一下， 最好在migration的时候做
    const realUrl = url.replace(
      'pub-7ca7933a1c084e4aae7d69af4f37609a.r2.dev',
      'cdn.woxcloud.com'
    )
    return `${CLOUDFLARE_IMAGE_URL}fit=cover,width=${size},height=${size}/${realUrl}`
  }

  return avatar
    ? getCloudflareImageUrl(avatar, avatarSize)
    : `https://ui-avatars.com/api/?name=${encodeURIComponent(name || '')}&background=random&color=#fff&font-size=0.4`
}
