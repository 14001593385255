import React, { ChangeEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

import { ReservationShowEntity, ResourceEntity } from '@/api'
import { KBForm, KBIcon, KBInput, KBTooltip } from '@/components/atoms'
import { GroupEditType } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'

/**
 * 备注
 */
interface NoteInputProps {
  value?: string
  resourcesList: ResourceEntity[]
  reservationInfo?: ReservationShowEntity
  groupEditType?: GroupEditType
  errorsMessages?: string
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
}
const NoteInput: React.FC<NoteInputProps> = (props) => {
  const { t } = useTranslation()
  const { resourcesList, reservationInfo, groupEditType, errorsMessages } =
    props

  /**
   * 备注校验
   */
  /**
  const validateNote = (notes: string) => {
    if (notes) {
      const cannotInputNotes = resourcesList.filter(
        (i) => !(i.reservation_form_rule?.rules as TReservationFormRule)?.notes
      )
      if (cannotInputNotes?.length) {
        return Promise.reject(
          new Error(
            `${cannotInputNotes.map((i) => i.name).join('、')}${tr('[intl]不允许输入备注')}`
          )
        )
      }
      return Promise.resolve()
    } else {
      return Promise.resolve()
    }
  }
  */

  const showNote = useMemo(() => {
    return (resourcesList || []).some((i) => {
      const rules = i.reservation_reserve_rule?.rules
      return rules?.notes
    })
  }, [resourcesList])

  const disabled = useMemo(() => {
    if (!reservationInfo) return false

    const { is_group } = reservationInfo

    return groupEditType === 'current'
  }, [reservationInfo])

  return showNote ? (
    <KBForm.Item
      name="notes"
      label={
        <KBTooltip title={t('note')}>
          <KBIcon name="text" size={24} />
        </KBTooltip>
      }
      dependencies={['resourceIds']}
      // rules={[
      //   {
      //     validator: (_, notes) => validateNote(notes),
      //   },
      // ]}
      validateStatus={Boolean(errorsMessages?.length) ? 'error' : undefined}
      help={errorsMessages}
    >
      <KBInput.TextArea
        placeholder={t('common.remarksPlaceholder')}
        disabled={disabled}
        rows={4}
      />
    </KBForm.Item>
  ) : null
}

export default NoteInput
