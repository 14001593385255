import Axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { getDefaultStore } from 'jotai'

import { kbNotification } from '@/components/atoms/KBMessageGlobal'
import {
  currentLocationIdAtom,
  currentSpaceIdAtom,
  currentUserTokenAtom,
} from '@/store'
import { BACKEND_URL } from '@/utils/constants'

const store = getDefaultStore()

let CURRENT_USER_TOKEN: string | undefined = undefined
let CURRENT_LOCATION_ID: number | undefined = undefined

export const setCurrentUserToken = (token?: string) => {
  CURRENT_USER_TOKEN = token
}

export const setCurrentLocationId = (locationId?: number) => {
  CURRENT_LOCATION_ID = locationId
}

const HTTP_STATUS_MESSAGES: { [key: number]: string } = {
  200: 'Success',
  201: 'Created',
  204: 'No Content',
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  409: 'Conflict',
  429: 'Too Many Requests',
  500: 'Internal Server Error',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
}

interface WoxServerError {
  message: string[] | string
  error: string
  statusCode: number
  errorCode?: number
}

const AXIOS_INSTANCE = Axios.create({
  timeout: 45000,
  baseURL: `${BACKEND_URL}`,
})

AXIOS_INSTANCE.interceptors.request.use(
  (config) => {
    try {
      const currentSpaceId = store.get(currentSpaceIdAtom)
      // console.log(`currentSpaceId: ${currentSpaceId}`)
      config.headers['x-client'] = 'web'
      if (currentSpaceId) {
        config.headers['X-space'] = currentSpaceId
      }
      const currentLocationId =
        CURRENT_LOCATION_ID || store.get(currentLocationIdAtom)
      if (currentLocationId) {
        config.headers['X-location'] = currentLocationId
      }
      const currentUserToken =
        CURRENT_USER_TOKEN || store.get(currentUserTokenAtom)
      // console.log(`currentUserToken: ${currentUserToken}`)
      if (currentUserToken) {
        config.headers['Authorization'] = `Bearer ${currentUserToken}`
      }
    } catch (e) {
      console.error('Error in axios interceptor', e)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const axiosClient = async <TData, TVariables = unknown>(
  config: AxiosRequestConfig<TVariables>
): Promise<TData> => {
  const promise = AXIOS_INSTANCE.request<TData>({
    ...config,
    // paramsSerializer: { indexes: null },
  })
    .then((res) => {
      return res
    })
    .catch((e: AxiosError<WoxServerError>) => {
      const description = e.response?.data?.message || e.message
      let errorMessage = description
      if (Array.isArray(description)) {
        errorMessage = description.join(', ')
      }

      const status = e.response?.status
      const errorData = e.response?.data
      let message
      if (status) {
        message =
          HTTP_STATUS_MESSAGES[status] || `An error occurred (code: ${status})`
      } else {
        message = 'Unknown error'
      }

      // 目前暂定422为后端校验的状态，不走报错提示
      const isValidateError = status === 422 && errorData?.errorCode === 422422
      if (!isValidateError) {
        kbNotification.error({
          message: message,
          description: errorMessage,
          key: message, // key is used to prevent duplicate notifications
        })
      }

      throw e
    })

  //@ts-ignore
  return promise
}

export default axiosClient
