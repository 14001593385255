// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ResourceReservationAdminCreateMutationRequest, ResourceReservationAdminCreateMutationResponse, ResourceReservationAdminCreate401 } from "../models/ResourceReservationAdminCreate";
import type { UseMutationOptions } from "@tanstack/react-query";

 type ResourceReservationAdminCreateClient = typeof client<ResourceReservationAdminCreateMutationResponse, ResourceReservationAdminCreate401, ResourceReservationAdminCreateMutationRequest>;
type ResourceReservationAdminCreate = {
    data: ResourceReservationAdminCreateMutationResponse;
    error: ResourceReservationAdminCreate401;
    request: ResourceReservationAdminCreateMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: ResourceReservationAdminCreateMutationResponse;
    client: {
        parameters: Partial<Parameters<ResourceReservationAdminCreateClient>[0]>;
        return: Awaited<ReturnType<ResourceReservationAdminCreateClient>>;
    };
};
/**
 * @summary 创建单个或者集合预定，根据resource_ids传值来判断，如果是多个值的情况下表示集合预定
 * @link /api/v5/admin/resource-reservations
 */
export function useResourceReservationAdminCreate(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<ResourceReservationAdminCreate["response"], ResourceReservationAdminCreate["error"], {
        data?: ResourceReservationAdminCreate["request"];
    }>;
    client?: ResourceReservationAdminCreate["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<ResourceReservationAdminCreate["data"], ResourceReservationAdminCreate["error"], ResourceReservationAdminCreate["request"]>({
                method: "post",
                url: `/api/v5/admin/resource-reservations`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "admin"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}